<mat-progress-bar value="100"></mat-progress-bar>
<mat-progress-bar mode="buffer"></mat-progress-bar>

<mat-card class="container-fluid">

   <!---------Primeiro card Camila------------>

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Camila Ferreira Ribeiro</mat-card-title>
      <mat-card-subtitle>cahferreira</mat-card-subtitle>
      <button class="coracao" mat-icon-button>
        <img class="fav" mat-card-image [src]="favorite" alt="coracao1">
      </button>
    </mat-card-header>

    <div class="foto" *ngIf="mySource" alt="1">
      <img [src]="avanade">
      <a>{{ nome }}</a>
    </div>

    <div class="foto" *ngIf="!mySource" alt="2">
      <img [src]="camila" alt="foto">
    </div>

    <mat-card-actions class="row-texto">
      <button id="one" mat-button (click)="onBotaoPhoto()">
        Quem Sou!?
      </button>
      <button id="like1" mat-button (click)="botaoLike()">
        like
      </button>
    </mat-card-actions>
    <div id="test"></div>
  </mat-card>

  <!---------Segundo card Iglá------------>

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Iglá Generoso</mat-card-title>
      <mat-card-subtitle>Iglá</mat-card-subtitle>
      <button class="coracao" mat-icon-button>
        <img class="fav" mat-card-image [src]="favorite2" alt="coracao2">
      </button>
    </mat-card-header>
    <div class="foto" *ngIf="mySource2" alt="1">
      <img [src]="dio">
      <a>{{ nome2 }}</a>
    </div>
    <div class="foto" *ngIf="!mySource2" alt="2">
      <img [src]="igla" alt="foto">
    </div>

    <mat-card-actions class="row-texto">
      <button id="one" mat-button (click)="onBotaoPhoto2()">
        Quem Sou!?
      </button>
      <button id="like1" mat-button (click)="botaoLike2()">
        like
      </button>
    </mat-card-actions>
    <div id="test"></div>
  </mat-card>

  <!---------Terceiro card Arnaldo------------>

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title id="name">Arnaldo Dos Santos Junior</mat-card-title>
      <mat-card-subtitle id="name">arnaldo-junior</mat-card-subtitle>
      <button class="coracao" mat-icon-button>
        <img class="fav" mat-card-image [src]="favorite3" alt="coracao3">
      </button>
    </mat-card-header>
    <div class="foto" *ngIf="mySource3" alt="1">
      <img [src]="avanade">
      <a>{{ nome3 }}</a>
    </div>
    <div class="foto" *ngIf="!mySource3" alt="2">
      <img [src]="arnaldo" alt="foto">
    </div>
    <mat-card-actions class="row-texto">
      <button id="one" mat-button (click)="onBotaoPhoto3()">
        Quem Sou!?
      </button>
      <button id="like1" mat-button (click)="botaoLike3()">
        like
      </button>
    </mat-card-actions>
    <div id="test"></div>
  </mat-card>

  <!---------Quarto card Rafael------------>

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Rafael Orofino</mat-card-title>
      <mat-card-subtitle>Orofino</mat-card-subtitle>
      <button class="coracao" mat-icon-button>
        <img class="fav" mat-card-image [src]="favorite4" alt="coracao4">
      </button>
    </mat-card-header>
    <div class="foto" *ngIf="mySource4" alt="1">
      <img src="{{ avanade }}">
      <a>{{ nome4 }}</a>
    </div>
    <div class="foto" *ngIf="!mySource4" alt="2">
      <img src="{{ rafael }}" alt="foto">
    </div>
    <mat-card-actions class="row-texto">
      <button id="one" mat-button (click)="onBotaoPhoto4()">
        Quem Sou!?
      </button>
      <button id="like4" mat-button (click)="botaoLike4()">
        like
      </button>
    </mat-card-actions>
  </mat-card>
</mat-card>


