<mat-progress-bar value="100"></mat-progress-bar>

<mat-progress-bar mode="buffer"></mat-progress-bar>
<mat-card class="container-fluid selection">
<mat-horizontal-stepper class="container">

  <!---------Etapas do Stepper------------>
  <mat-step label="Primeira Etapa" state="phone">
    <h3>BootCamp Dio.</h3>
    <img id="img1" mat-card-image src="assets/img/banner.png" alt="Digital Inivation One"> <img class="cod"
      mat-card-image src="/assets/img/qrcode1.png" id="foto1">
  </mat-step>
  <mat-step label="Segunda Etapa" state="chat">
    <h3>Concluir o BootCamp.</h3>
    <img id="img2" mat-card-image src="assets/img/diploma.png" alt="Diploma">
  </mat-step>
  <mat-step label="Terceira Etapa" state="chat">
    <h3>Entrevista Avanade. <mat-icon id="fav">thumb_up</mat-icon>
    </h3>
    <img id="img3" mat-card-image src="assets/img/arnaldo.png" alt="Entrevista">
  </mat-step>
  <mat-step label="Quarta Etapa">
    <h3>Welcome Avanade.</h3>
    <img id="img4" mat-card-image src="/assets/img/benvindo.jpg" alt="Welcome Avanade">
  </mat-step>

  <!-- Icon overrides. -->
  <ng-template matStepperIcon="phone">
    <mat-icon>call_end</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="chat">
    <mat-icon>forum</mat-icon>
  </ng-template>
</mat-horizontal-stepper>

</mat-card>
