import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'spa-header',
  templateUrl: '../header/header.component.html',
  styleUrls: ['../header/header.component.css'],
})
export class HeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
