<mat-toolbar class="container-fluid footer">
    <strong class="dev">
      <p> Desenvolvido por</p>
       <a href="https://www.linkedin.com/in/danilo-portor/"
        target="_blank" rel="noopener">&nbsp;Danilo Porto Rosa
       </a>
      <i class="material-icons v-middle">account_balance</i>
      <span class="orange">#Avanade</span>
    </strong>
</mat-toolbar>
