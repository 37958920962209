
<mat-toolbar class="container-fluid header">

  <img class="logo"  src="assets/img/logo.png" alt="logo"routerLink="/home">

  <span>Bootcamp Avanade Angular</span>

</mat-toolbar>

<mat-card class="menu">
  <button id="home" mat-button routerLink="/home">
    Home<i class="material-icons v-middle">
      account_balance
    </i>
  </button>
  <button id="selecao" mat-button routerLink="/selection">Seleção<i class="material-icons">
      assignment_ind </i>
  </button>
  <button id="selecao" mat-button routerLink="/about">About<i class="material-icons">
      list_alt </i>
  </button>
</mat-card>
